import { createIcon } from '@chakra-ui/react'

export const PortisIcon = createIcon({
  displayName: 'PortisIcon',
  path: (
    <svg>
      <style>
        {
          '.cls-1{fill:#133444;}.cls-2{fill:#c42370;}.cls-3{fill:#1c4d6b;}.cls-4{fill:#1d4259;}.cls-5{fill:#4b6b9a;}.cls-6{fill:#343535;}.cls-7{fill:#3e5578;}.cls-8{fill:#6db2d8;}.cls-9{fill:#335f8a;}.cls-10{fill:#529bba;}'
        }
      </style>

      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <polygon
            className='cls-1'
            points='4.45 262.11 15.38 257.36 150.69 198.54 296.93 262.11 241.98 375.54 107.65 394.54 6.65 285.54 4.45 262.11'
          />
          <path
            className='cls-2'
            d='M266.51,192.16a150.43,150.43,0,0,0-95.82-53q-1.2-.17-2.4-.3a152.36,152.36,0,0,0-35.2,0q-1.2.14-2.4.3a150.43,150.43,0,0,0-95.82,53h0l-6.08,10.08L18.66,219l-7.41,12.31v0c-.14.33-.28.67-.41,1l-.09.23,16.35,9.67,103.6,61.28,20,11.83V168.85l-20,9.08V156l20-9.09,20,9.09,101.9,46.28Z'
          />
          <path
            className='cls-3'
            d='M301.38,298.56c0,48.78-23.18,94.42-59.12,123.84a149.45,149.45,0,0,1-47.64,26.37,139.51,139.51,0,0,1-43.93,7.1C67.47,455.87,0,381.78,0,298.56a150.49,150.49,0,0,1,4.45-36.45l146.24,86.51,146.24-86.51A150.49,150.49,0,0,1,301.38,298.56Z'
          />
          <polygon points='133.09 138.89 150.69 146.88 130.69 155.97 130.69 137.8 133.09 138.89' />
          <polygon points='130.69 177.93 150.69 168.85 150.69 315.38 130.69 303.55 130.69 177.93' />
          <polygon
            className='cls-4'
            points='150.69 168.85 150.69 315.38 290.82 232.49 150.69 168.85'
          />
          <polygon className='cls-5' points='150.69 0 150.69 168.85 290.82 232.49 150.69 0' />
          <path
            className='cls-6'
            d='M150.69,168.85V315.38l-20-11.83-119.95-71-.18-.11.27-.12c.13-.33.27-.67.41-1v0l17.53-29.09L130.69,156v22Z'
          />
          <polygon
            className='cls-7'
            points='150.69 168.85 150.69 315.38 130.69 303.55 10.74 232.6 10.56 232.49 10.83 232.37 130.69 177.93 150.69 168.85'
          />
          <polygon className='cls-8' points='150.69 0 150.69 168.85 10.56 232.49 150.69 0' />
          <path
            className='cls-9'
            d='M94,443.85c2.09.92,4.21,1.79,6.34,2.61Q97.08,445.23,94,443.85Z'
          />
          <path
            className='cls-9'
            d='M100.29,446.46c2.14.82,4.29,1.59,6.47,2.31Q103.49,447.7,100.29,446.46Z'
          />
          <path
            className='cls-9'
            d='M112.33,450.49c1.66.47,3.33.92,5,1.33Q114.82,451.2,112.33,450.49Z'
          />
          <path
            className='cls-8'
            d='M4.45,262.11l146.24,86.51A167.48,167.48,0,0,1,140.22,387c-11.7,28.28-35.34,58.19-81.08,35.44h0C23.18,393,0,347.34,0,298.56A150.49,150.49,0,0,1,4.45,262.11Z'
          />
          <path
            className='cls-10'
            d='M242.24,422.41l-.36.29c-.76.62-1.52,1.23-2.29,1.83l-.2.16q-1.25,1-2.52,1.92c-.91.68-1.83,1.36-2.76,2s-1.86,1.31-2.8,1.95-1.66,1.12-2.49,1.66l-.46.3c-.88.57-1.76,1.13-2.64,1.67a2.63,2.63,0,0,1-.33.2c-.91.57-1.82,1.12-2.74,1.65s-2,1.15-3,1.7-2,1.08-3,1.6l-.11.06c-1,.52-2,1-3,1.52s-2,1-3.08,1.49-2.08,1-3.12,1.42c-2.09.92-4.21,1.79-6.34,2.61s-4.29,1.59-6.47,2.31c-.87.29-1.74.57-2.62.84s-2,.6-2.95.88c-1.66.47-3.33.92-5,1.33-.63.16-1.27.31-1.91.45l-1.07.25c-.89.2-1.78.39-2.68.57s-1.64.33-2.47.48-1.51.28-2.27.4-1.68.27-2.52.39c-.41.07-.82.13-1.24.18l-1.89.25-1.05.13c-.69.09-1.39.16-2.09.23-.86.09-1.71.17-2.57.24-.67.05-1.34.11-2,.15s-1.16.08-1.75.1h-.08c-.68,0-1.36.08-2,.1-.94,0-1.89.07-2.84.09s-1.9,0-2.86,0-1.91,0-2.86,0-1.9,0-2.84-.09c-.69,0-1.37-.06-2.05-.1h-.08c-.59,0-1.17-.06-1.75-.1s-1.34-.1-2-.15c-.86-.07-1.71-.15-2.57-.24-.7-.07-1.4-.14-2.09-.23l-1-.13c-.63-.07-1.26-.16-1.89-.25-.42,0-.83-.11-1.24-.18q-1.27-.18-2.52-.39c-.76-.12-1.52-.26-2.27-.4s-1.65-.31-2.47-.48-1.79-.37-2.68-.57l-1.07-.25c-.64-.14-1.28-.29-1.91-.45-1.68-.41-3.35-.86-5-1.33l-3-.88c-.88-.27-1.75-.55-2.62-.84-2.18-.72-4.33-1.49-6.47-2.31S96,444.77,94,443.85c-1-.46-2.08-.94-3.12-1.42s-2.06-1-3.08-1.49-2-1-3-1.52l-.11-.06c-1-.52-2-1.06-3-1.6s-2-1.12-3-1.7S76.9,435,76,434.41l-.33-.2c-.88-.54-1.76-1.1-2.64-1.67l-.46-.3c-.83-.54-1.67-1.1-2.49-1.66s-1.88-1.29-2.8-1.95-1.85-1.34-2.76-2-1.69-1.27-2.52-1.92l-.2-.16c-.77-.6-1.53-1.21-2.29-1.83l-.36-.29c45.74,22.75,69.38-7.16,81.08-35.44a167.48,167.48,0,0,0,10.47-38.35A167.47,167.47,0,0,0,161.16,387C172.86,415.25,196.5,445.16,242.24,422.41Z'
          />
          <path
            className='cls-9'
            d='M184,451.82c1.68-.41,3.35-.86,5-1.33Q186.56,451.21,184,451.82Z'
          />
          <path
            className='cls-9'
            d='M194.62,448.77c2.18-.72,4.33-1.49,6.47-2.31Q197.9,447.7,194.62,448.77Z'
          />
          <path
            className='cls-9'
            d='M201.09,446.46c2.13-.82,4.25-1.69,6.34-2.61Q204.3,445.23,201.09,446.46Z'
          />
          <path
            className='cls-5'
            d='M301.38,298.56c0,48.78-23.19,94.43-59.14,123.85-45.74,22.75-69.38-7.16-81.08-35.44a167.47,167.47,0,0,1-10.47-38.35l146.24-86.51A150.49,150.49,0,0,1,301.38,298.56Z'
          />
        </g>
      </g>
    </svg>
  ),
  viewBox: '0 0 301.38 455.87',
})

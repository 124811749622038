import { createIcon } from '@chakra-ui/react'

export const XDEFIIcon = createIcon({
  displayName: 'XDeFiIcon',
  path: (
    <svg
      width='350'
      height='350'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.2627 13.4013C12.4227 14.5307 9.95987 15.1124 7.46558 14.9881C5.36844 14.8865 3.64844 14.1411 2.60844 12.9157C1.69416 11.8201 1.33987 10.3745 1.57987 8.71143C1.66121 8.15874 1.82791 7.62166 2.07415 7.11896L2.10844 7.04837C2.9717 5.40595 4.25282 4.01397 5.82573 3.00942C7.39864 2.00486 9.20913 1.42236 11.079 1.31924C12.9489 1.21611 14.8137 1.59592 16.49 2.42128C18.1662 3.24665 19.5961 4.48912 20.6389 6.02641C21.6817 7.56371 22.3015 9.34285 22.4373 11.1887C22.5731 13.0346 22.2202 14.8836 21.4133 16.5538C20.6064 18.224 19.3734 19.6577 17.8355 20.714C16.2977 21.7702 14.508 22.4126 12.6427 22.5778L12.757 23.8738C14.8513 23.6894 16.8608 22.9691 18.5877 21.7837C20.3146 20.5984 21.6992 18.9889 22.6051 17.1138C23.511 15.2387 23.907 13.1627 23.7541 11.0904C23.6011 9.01808 22.9045 7.02088 21.7328 5.29555C20.5612 3.57022 18.9549 2.1763 17.0723 1.25111C15.1896 0.325909 13.0956 -0.0986411 10.9964 0.0192787C8.89722 0.137198 6.86533 0.79352 5.10091 1.92359C3.33649 3.05365 1.90042 4.61848 0.934157 6.4639L0.888441 6.55707C0.582976 7.1809 0.37674 7.84756 0.277013 8.53354C-0.00870132 10.5608 0.434153 12.3651 1.5913 13.7542C2.85701 15.2733 4.91701 16.1768 7.38844 16.2954C10.397 16.4451 13.3884 15.6347 15.5113 14.1439L14.2627 13.4013Z'
        fill='#2041E0'
      />
      <path
        d='M16.78 14.875C15.5829 15.9028 12.8 17.7663 8.18286 18.0204C3.01429 18.3028 0.860001 16.6425 0.840001 16.6256L0.422856 17.1338L0.842856 16.6341L0 17.6336C0.0914286 17.7098 2.15714 19.3588 7.00857 19.3588C7.40571 19.3588 7.82286 19.3588 8.25714 19.3249C13.8371 19.0171 16.9029 16.6115 17.9714 15.5837L16.78 14.875Z'
        fill='#2041E0'
      />
      <path
        d='M19.0199 16.2191C18.312 17.1386 17.4407 17.9232 16.4485 18.5344C12.9513 20.7649 8.50275 21.0529 5.38847 20.8976L5.32275 22.1993C5.84561 22.2247 6.34847 22.236 6.83704 22.236C15.6199 22.236 19.1685 18.2831 20.1599 16.8713L19.017 16.2078'
        fill='#2041E0'
      />
      <path
        d='M18.6856 11.2923C19.2679 11.2923 19.7399 10.8258 19.7399 10.2504C19.7399 9.67496 19.2679 9.2085 18.6856 9.2085C18.1034 9.2085 17.6313 9.67496 17.6313 10.2504C17.6313 10.8258 18.1034 11.2923 18.6856 11.2923Z'
        fill='#2041E0'
      />
    </svg>
  ),
  viewBox: '0 0 318.6 318.6',
})

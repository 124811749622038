import { createIcon } from '@chakra-ui/react'

export const SafesIcon = createIcon({
  displayName: 'SafesIcon',
  path: (
    <path
      d='M20 2C21.1 2 22 2.9 22 4V20C22 21.1 21.1 22 20 22H19V23H15V22H9V23H5V22H4C2.9 22 2 21.1 2 20V4C2 2.9 2.9 2 4 2H20ZM17 12C17 11 16.7 10 16.2 9.2L17.7 7.7L16.3 6.3L14.8 7.8C14 7.3 13 7 12 7C11 7 10 7.3 9.2 7.8L7.8 6.3L6.3 7.8L7.8 9.3C7.3 10 7 11 7 12C7 13 7.3 14 7.8 14.8L6.3 16.3L7.8 17.7L9.3 16.2C10 16.7 11 17 12 17C13 17 14 16.7 14.8 16.2L16.3 17.7L17.7 16.3L16.2 14.8C16.7 14 17 13 17 12ZM12 9C13.7 9 15 10.3 15 12C15 13.7 13.7 15 12 15C10.3 15 9 13.7 9 12C9 10.3 10.3 9 12 9ZM12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14Z'
      fill='#112137'
    />
  ),
  viewBox: '0 0 24 24',
})

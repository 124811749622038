import { Icon } from '@chakra-ui/react'

export const KeyStoreIcon = () => (
  <Icon viewBox='0 24 22' height='22px' width='24px'>
    <path
      d='M4.42857 11.7139H4.48194L13.2138 20.5H17.4458L7.80345 10.6361L16.9906 1.5H12.8122L4.48194 9.90854H4.42857V1.5H1V20.5H4.42857V11.7139Z'
      fill='#012A36'
    />
    <path
      d='M21.3105 20.4996C22.7128 20.4996 23.8497 19.3558 23.8497 17.9448C23.8497 16.5338 22.7128 15.3899 21.3105 15.3899C19.9081 15.3899 18.7712 16.5338 18.7712 17.9448C18.7712 19.3558 19.9081 20.4996 21.3105 20.4996Z'
      fill='#79C62B'
    />
  </Icon>
)

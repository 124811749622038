import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Button, Divider, Flex, ModalBody, ModalHeader, Stack } from '@chakra-ui/react'
import { Vault } from '@shapeshiftoss/hdwallet-native-vault'
import { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { RouteComponentProps } from 'react-router'
import { Text } from 'components/Text'
import { useStateIfMounted } from 'hooks/useStateIfMounted/useStateIfMounted'
import { selectFeatureFlag } from 'state/slices/selectors'
import { useAppSelector } from 'state/store'

export const NativeStart = ({ history }: RouteComponentProps) => {
  const [hasLocalWallet, setHasLocalWallet] = useStateIfMounted<boolean>(false)
  const translate = useTranslate()
  const walletMigrationFeatureFlag = useAppSelector(state =>
    selectFeatureFlag(state, 'WalletMigration'),
  )

  useEffect(() => {
    ;(async () => {
      try {
        const localWallets = await Vault.list()
        setHasLocalWallet(localWallets.length > 0)
      } catch (e) {
        console.error('WalletProvider:NativeWallet:Start - Cannnot enumerate Vault', e)
        setHasLocalWallet(false)
      }
    })()
  }, [setHasLocalWallet])

  return (
    <>
      <ModalHeader>
        <Text translation={'walletProvider.shapeShift.start.header'} />
      </ModalHeader>
      <ModalBody>
        <Text mb={4} color='gray.500' translation={'walletProvider.shapeShift.start.body'} />
        <Stack mt={6} spacing={4}>
          <Button
            variant='ghost-filled'
            colorScheme='lime'
            w='full'
            h='auto'
            px={6}
            py={4}
            justifyContent='space-between'
            rightIcon={<ArrowForwardIcon />}
            disabled={!hasLocalWallet}
            onClick={() => history.push('/native/load')}
            data-test='wallet-native-load-button'
          >
            <Text translation={'walletProvider.shapeShift.start.load'} />
          </Button>
          <Divider />
          <Button
            variant='ghost-filled'
            colorScheme='lime'
            w='full'
            h='auto'
            px={6}
            py={4}
            justifyContent='space-between'
            rightIcon={<ArrowForwardIcon />}
            onClick={() => history.push('/native/create')}
            data-test='wallet-native-create-button'
          >
            <Text translation={'walletProvider.shapeShift.start.create'} />
          </Button>
          <Button
            variant='ghost-filled'
            colorScheme='lime'
            w='full'
            h='auto'
            px={6}
            py={4}
            justifyContent='space-between'
            rightIcon={<ArrowForwardIcon />}
            onClick={() => history.push('/native/import')}
            data-test='wallet-native-import-button'
          >
            <Text translation={'walletProvider.shapeShift.start.import'} />
          </Button>
          {walletMigrationFeatureFlag && (
            <>
              <Divider mt={4} />
              <Flex
                direction={['column', 'row']}
                mt={2}
                pt={4}
                justifyContent='center'
                alignItems='center'
              >
                <Text translation={'walletProvider.shapeShift.legacy.haveMobileWallet'} />
                <Button
                  variant='link'
                  ml={[0, 1.5]}
                  borderTopRadius='none'
                  colorScheme='lime'
                  onClick={() => history.push('/native/legacy/login')}
                >
                  {translate('common.login')}
                </Button>
              </Flex>
            </>
          )}
        </Stack>
      </ModalBody>
    </>
  )
}

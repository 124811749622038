import { createIcon } from '@chakra-ui/react'

export const DashboardIcon = createIcon({
  displayName: 'CircularGraphIcon',
  path: (
    <path
      d='M13 3V9H21V3H13ZM13 21H21V11H13V21ZM3 21H11V15H3V21ZM3 13H11V3H3V13Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 24 24',
})

import { createIcon } from '@chakra-ui/react'

export const FeedbackIcon = createIcon({
  displayName: 'CircularGraphIcon',
  path: (
    <path
      d='M15 4V11H5.17L4 12.17V4H15ZM16 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17L6 13H16C16.2652 13 16.5196 12.8946 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12V3C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2ZM21 6H19V15H6V17C6 17.2652 6.10536 17.5196 6.29289 17.7071C6.48043 17.8946 6.73478 18 7 18H18L22 22V7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 24 24',
})
